import React from 'react'
import DashboardNav from '../Dashboard/DashboardNavbar/DashboardNav'
import Ludo from '../../Component/Ludo'
import logo from '../../image/logo.png'
import refer_earn_img from '../../image/refer-earn.png'
import '../Refer-Earn/ReferAndEarn.css'
import refer_earn from '../../image/refer-earn-1.png'
import refer_earn2 from '../../image/refer-earn-2.png'
import ReferAndEarnRules from './ReferAndEarnRules'
import Footer from '../../Component/Footer/Footer'

function ReferAndEarn() {
  return (
    <>
    <div className=" refer-earn-wraper">
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-4 col-12 bg-white p-0">
        <DashboardNav/>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-12 m-auto ms-4 '>
              <div className='row m-auto'>
                <div className='col-lg-8 col-md-8 m-auto mt-2 text-center'>
                  <picture>
                      <img className='img-fluid' src={refer_earn_img} alt='Refer and Earn'/>
                  </picture>
                </div>
                <div className='text-center mt-5'>
                    <h2>Earn now unlimited</h2>
                    <p>Refer your friends now!</p>
                    <h2>Your refer code: 987uyh</h2>
                    <p className='mb-1'>Total Refers : 0</p>
                    <p>Total Earnings : 0</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='container-fluid null-box'></div>

        <div className='container'>
            <h1 className='ms-5 earn-heading'>Refers & Earn Rules </h1>
            <div className='row'>
                    <ReferAndEarnRules image={refer_earn}/>
                    <ReferAndEarnRules image={refer_earn2}/>
            </div>
        </div>
        
        <div className='container-fluid share-button-wraper'>
            <div className='row'>
                <div className='col-8 m-auto'>
                    <button><span>SEND OTP</span></button>
                </div>
            </div>
        </div>
    <Footer/>
      </div>
      <div className="col-lg-8 right-section   text-center my-5">
      <div className="right-position-sticky">
      <picture>
      <img src={logo} />
    </picture>
    <Ludo />
      </div>
      </div>

    </div>
      </div>
    </div>
    </> 
  )
}

export default ReferAndEarn
