import React, { useContext, useEffect, useState } from "react";
import Ludo from "../../../src/Component/Ludo";
import logo from "../../image/logo.png";
import DashboardNav from "../Dashboard/DashboardNavbar/DashboardNav";
import "../Add-money/AddMoney.css";
import Footer from "../../Component/Footer/Footer";
import axios from "axios";
import { LUDO_API } from "../../config";
import { KycContext } from "../../context/AuthContext";
import { format } from 'date-fns';
import { Fragment } from "react";

  function WithdrawlHistory() {
  const [state , setState] = useState({balance:''})
  const{kycstatus,userCrendential} = useContext(KycContext)
  const [data, setData] = useState([]);
  const [adminPhoneNo , setAdminNo] = useState('')
  const[errorMsg , setErrorMsg] = useState('')
  console.log(adminPhoneNo)
  console.log(state)

  const handleSubmit = (e) => {
    e.preventDefault()
    axios.post(`${LUDO_API}/api/wallet/withdrawal-request-by-user` , {
        user_id:userCrendential.user_id,
        amount:state.balance
    })
    .then(response=>{
      console.log(response)
    //   window.location.reload()
    alert(response.data.message)
    })
    .catch(err=>{
      console.log(err)
      setErrorMsg(err.response.data.error)
    })
  }

  
  
useEffect(()=>{
  axios.get(LUDO_API + "/api/page/settings/list")
  .then(response=>{
    console.log(response.data)
    setAdminNo(response.data.phone)
  })
  .catch(err=>{
    console.log(err)
  })
},[])

useEffect(() => {
    axios.get(LUDO_API + "/api/wallet/get-withdrawl-request-by-id?id=" + userCrendential.user_id)
        .then(response => {
            console.log(response)
            // setData(response.data);
        })
        .catch(err => {
            console.log(err);
            setData([])
        });
}, [userCrendential.user_id]);

const formatDateTime = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = format(date, 'dd MMM');
    const formattedTime = format(date, 'h:mm a');
    return { formattedDate, formattedTime };
};
  return (
    <>
      
      <div className="dashboard-wraper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 bg-white p-0 pb-5 position-relative">
              <DashboardNav />
              
            <form onSubmit={handleSubmit }>
              <div className="container m-auto" style={{ width: "80%" }}>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-12 m-auto">
                    <div className="amount-wraper w-100 mt-5">
                      <label htmlFor="add-money">Enter Withdrawl Amount</label> <br />
                      
                      <input
                      type="number"
                      id="add-money "
                      style={{
                        width: "100%",
                        border: "none",
                        outline: "none",
                        borderBottom: "1px solid  #5F5A5A",
                        padding: "10px 0",
                      }}
                      placeholder="Rs."
                      onChange={(e)=>setState({...state , balance:e.target.value})}
                    />
                      
                      <h2>Min. 100 , Max. 25,000</h2>
                      <p className="text-danger">{errorMsg}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="payment-card-wraper">
                <div className="container">
                  <div className="row justify-content-between">
                   
                    <button className="my-3 mb-5">
                      <span>Request for withdrawl</span>
                    </button>
                  </div>
                </div>
              </div>

              </form>

            <div className="container withdrawl-history-container">
            {
                data.map((record,index)=>{
                    const { formattedDate, formattedTime } = formatDateTime(record.created_at);
                    return (
                        <Fragment>
                <div className='container order-section mt-3'>
                <div className='row'>
                    <div className='order-wraper col-9  d-flex'>
                        <div className='date-time-wraper'>
                        <p>{formattedDate}</p>
                        <p className='m-0'>{formattedTime}</p>
                        </div>
                        <div className='order-id-wraper' >
                            <h2 >{record.type}</h2>
                            <p>Order id : {record.order_id}</p>
                        </div>
                    </div>
                    <div className='col-3 d-flex align-center justify-content-evenly'>
                    <span className='add-icon'>(+)</span>
                    <div className='d-flex align-center'>
            {
                // <span><img className='img-fluid' src={money_img}/></span>
            
            }        <h2 className='m-0 ms-2'>{record.balance}</h2>
                    </div>
                    </div>
                </div>
            </div>
            {
            //     <div className='container order-section mt-3'>
            //     <div className='row'>
            //         <div className='order-wraper col-9  d-flex'>
            //             <div className='date-time-wraper'>
            //             <p>16 Jan</p>
            //             <p className='m-0'>17:35 pm</p>
            //             </div>
            //             <div className='order-id-wraper' >
            //                 <h2 >{record.type}</h2>
            //                 <p>Order id : Cash78499555555</p>
            //             </div>
            //         </div>
            //         <div className='col-3 d-flex align-center justify-content-evenly'>
            //         <span className='subtract-icon'>(-)</span>
            //         <div className='d-flex align-center'>
            //         <span><img className='img-fluid' src={money_img}/></span>
            //         <h2 className='m-0 ms-2'>800</h2>
            //         </div>
            //         </div>
            //     </div>
            // </div>
            }
                </Fragment>
                    )
                })
               }
            </div>

              <Footer />
            </div>
            <div className="col-lg-8 col-md-12 text-center my-5 right-section">
              <div className="right-position-sticky">
                <picture>
                  <img src={logo} />
                </picture>
                <Ludo />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WithdrawlHistory;
