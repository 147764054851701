import React from 'react'
import DashboardNav from '../Dashboard/DashboardNavbar/DashboardNav'
import logo from '../../image/logo.png'
import Ludo from '../../Component/Ludo'
import identification from '../../image/Identification.png'
import document_img from '../../image/Documents.png'
import verified_id_img from '../../image/ID-Verified.png'
import home_img from '../../image/Home.png'
import '../KycDashboard/kycprofile.css'
import Header from '../../Component/Header/Header'
import Footer from '../../Component/Footer/Footer'

function KycProfile() {
  return (
    <>
    <Header/>
    <div className="kyc-wraper">
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-4 bg-white p-0">
        <DashboardNav/>
        <div className='container'>
          <div className='row m-auto'>
            <div className='col-12 m-auto  kyc-profile-wraper'>
              <div className='row py-5'>
                <div className='col-lg-12 col-md-12 m-auto mt-2'>
                  <div className=' user-info-wraper mt-4'>
                  <picture>
                    <img className='img-fluid'  src={identification}/>
                  </picture>
                  <p className='d-inline'>Aadhar Number :- 7485966699815</p>
                  </div>
                  <div className=' user-info-wraper mt-4'>
                  <picture>
                    <img  className='img-fluid' src={document_img}/>
                  </picture>
                  <p className='d-inline'>Name :- Lilly</p>
                  </div>
                  <div className=' user-info-wraper mt-4'>
                  <picture>
                    <img className='img-fluid' src={verified_id_img}/>
                  </picture>
                  <p className='d-inline'>DOB : 17-09-2001</p>
                  </div>
                  <div className=' user-info-wraper mt-4 col-sm-mb-5'>
                  <picture>
                    <img className='img-fluid' src={home_img}/>
                  </picture>
                  <p className='d-inline ' >Address : 26985 Brighton Lane</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer/>
      </div>
      <div className="col-lg-8 right-section   text-center my-5">
      <div className="right-position-sticky">
      <picture>
      <img src={logo} />
    </picture>
    <Ludo />
      </div>
      </div>
    </div>
      </div>
    </div>
    </> 
  )
}

export default KycProfile
