import React from "react";
import DashboardNav from "../Dashboard/DashboardNavbar/DashboardNav";
import Ludo from "../../Component/Ludo";
import logo from "../../image/logo.png";
import "../MyProfile/walletProfile.css";
import replay from "../../image/Replay.png";
import WalletCard from "./WalletCard";
import { Link } from "react-router-dom";
import Footer from "../../Component/Footer/Footer";


function WalletProfile() {
  return (
    <>
      <div className="kyc-wraper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 bg-white p-0">
              <DashboardNav />

              <div className="container">
                <div className="row">
                  <Link to='/transaction' className="text-decoration-none"><div className="col-11  replay-wraper ">
                  <img className="replay-img" src={replay} alt="replay" />
                  <span className="history-wraper">Order History</span>
                </div></Link>
                </div>
              </div>

              <div className="container-fluid null-box"></div>

              <div className="container">
                <div className="row w-100 m-auto">
                  <div className="button-wraper">
                    <WalletCard value='add cash'/>  
                    <WalletCard value='withdraw'/>  
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
            <div className="col-lg-8 right-section   text-center my-5">
            <div className="right-position-sticky">
            <picture>
            <img src={logo} />
          </picture>
          <Ludo />
            </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WalletProfile;
