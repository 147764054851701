// npm install --save laravel-echo pusher-js


import React, { useState, useEffect } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

function WebSocketComponent() {
    const [battles, setBattles] = useState([]);

    useEffect(() => {
        window.Pusher = Pusher;

        const echo = new Echo({
            broadcaster: 'pusher',
            key: '1d638d962ef61b87f264',
            cluster: 'ap2',
            forceTLS: true
        });

        echo.channel('battles')
            .listen('BattleUpdated', (e) => {
                console.log('Battle updated:', e.battles);
                setBattles(e.battles);
            });

        return () => {
            echo.disconnect();
        };
    }, []);

    return (
        <div>
            <h1>Battles List</h1>
            <ul>
                {battles.map((battle) => (
                    <li key={battle.id}>{battle.full_name} - {battle.battle_amount}</li>
                ))}
            </ul>
        </div>
    );
}



export default WebSocketComponent;
