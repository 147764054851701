import { initializeApp } from "firebase/app";


const firebaseConfig = {
 apiKey: "AIzaSyBAkYeBxRG47F-A7VAUEFdUoEeM3qpXe7M",
  authDomain: "kingstarludo-39bb0.firebaseapp.com",
  projectId: "kingstarludo-39bb0",
  storageBucket: "kingstarludo-39bb0.appspot.com",
  messagingSenderId: "656377466002",
  appId: "1:656377466002:web:9a25fabe0650f182fdaa4b",
  measurementId: "G-MYJ5KN5RWH"
};

// local setup for firebase 
// apiKey: "AIzaSyBehbutcqfUUHKueWWrfeInQTIX0osh9pg",
// authDomain: "ludo-c0679.firebaseapp.com",
// projectId: "ludo-c0679",
// storageBucket: "ludo-c0679.appspot.com",
// messagingSenderId: "661386095660",
// appId: "1:661386095660:web:90449abedb1089e776f589",
// measurementId: "G-GVMPM5QFK2"
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app
