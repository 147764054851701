import React, { useEffect, useState } from "react";
import "../LiveBattle/LiveBattle.css"; // Assuming you have some additional CSS styling
import money_img from "../../image/Money.png";
import customer from '../../image/customer.png';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import { LUDO_API } from "../../config";
import axios from "axios";
function RunningBattleComponent() {
  const[getRoomData , setgetRoomData] = useState([])

  // Fetch initial battle data
  const fetchBattles = async () => {
    try {
        const response = await axios.get(LUDO_API+'/api/battles/runnning-battle-listing');
        setgetRoomData(response.data.message);
    } catch (error) {
        console.error("Error fetching battles:", error);
    }
};
  useEffect(() => {

    fetchBattles()
    window.Pusher = Pusher;
  
    const echo = new Echo({
        broadcaster: 'pusher',
        key: '1d638d962ef61b87f264',
        cluster: 'ap2',
        forceTLS: true
    });
  
    echo.channel('battlerunning')
        .listen('RunningBattle', (e) => {
            console.log('battle_running:', e.battlerunning);
            const lastData = e.battlerunning
            // console.log(lastData)
            setgetRoomData(lastData);
        });
  
    return () => {
        echo.disconnect();
    };
  }, []);
  return (
 getRoomData.map((record,index)=>(
  <div className="col-lg-12 col-md-8 m-auto mt-3 live-info-wraper" key={index}>
      <div className="d-flex justify-content-between entry-fee-wraper">
        <div>
          <p className="d-inline entry-fee text-uppercase">
            entry fee
          </p>
          <span>
            <img
              className="mx-2 img-fluid mb-2"
              src={money_img}
              alt="Money"
            />
            <h2 className="m-0">{record.battle_amount
            }</h2>
          </span>
        </div>
        <div className="px-3">
          <p className="d-inline entry-fee text-uppercase">
            prize
          </p>
          <span >
            <img
              className="mx-2 img-fluid mb-2"
              src={money_img}
              alt="Money"
            />
            <h2 className="m-0">{record.win_amount}</h2>
          </span>
        </div>
      </div>
      <div className="d-flex justify-content-between entry-fee-wraper">
        <div>
          <span className="p-3">
            <img
              className="mx-2 img-fluid mb-2"
              src={customer}
              alt="Customer"
            />
            <h2 className="m-0 text-uppercase">{record.fullname}</h2>
          </span>
        </div>
        <span>V/S</span>
        <div>
          <span className="p-3">
            <img
              className="mx-2 img-fluid mb-2 "
              src={customer}
              alt="Customer"
            />
            <h2 className="m-0 text-uppercase">{record.other_fullname}</h2>
          </span>
        </div>
      </div>
    </div>
 ))
  );
}

export default RunningBattleComponent;
