import React from 'react'
function ReferAndEarnRules({image}) {
  return (
    <div className='col-11 m-auto mt-4'>
    <div className='row'>
        <div className='col-3  text-center'>
            <picture>
                <img className='img-fluid' src={image}/>
            </picture>
        </div>
        <div className='col-7  py-2  refer-earn-rules-wraper'>
            <h2>When your friend sign up on Kingstarludo From 
            your referral link,</h2>
            <p>You get 1% commission on your referral’s winning</p>
        </div>
    </div>
    </div>
  )
}

export default ReferAndEarnRules
