import React, { Fragment, useContext, useEffect, useState } from 'react';
import money_img from '../../image/Money.png';
import axios from 'axios';
import { KycContext } from '../../context/AuthContext';
import { LUDO_API } from '../../config';
import { format } from 'date-fns';
import empty_referrals from '../../image/empty_referrals-history.png'

function Lost_Win() {
    const { userCrendential } = useContext(KycContext);
    const [data, setData] = useState([]);
    console.log(userCrendential);

    useEffect(() => {
        axios.get(LUDO_API + "/api/get-user-game-history?user_id=" + userCrendential.user_id)
            .then(response => {
                setData(response.data.data);
            })
            .catch(err => {
                console.log(err);
            });
    }, [userCrendential.user_id]);

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'dd MMM');
        const formattedTime = format(date, 'h:mm a');
        return { formattedDate, formattedTime };
    };

    return (
        <>
       { data.map((record, index) => {
                const { formattedDate, formattedTime } = formatDateTime(record.created_at);
            return (
                <Fragment key={index}>
                    <div className='container order-section mt-3'>
                        <div className='row'>
                            <div className='order-wraper col-9 d-flex'>
                                <div className='date-time-wraper'>
                                    <p>{formattedDate}</p>
                                    <p className='m-0'>{formattedTime}</p>
                                </div>
                                <div className='order-id-wraper'>
                                    <h2>Win against {record.won_username}</h2>
                                    <p>Order id: {record.creater_id}</p>
                                </div>
                            </div>
                            <div className='col-3 d-flex align-center justify-content-evenly'>
                                <span className='add-icon'>(+)</span>
                                <div className='d-flex align-center'>
                                    <span><img className='img-fluid' src={money_img} alt="Money"/></span>
                                    <h2 className='m-0 ms-2'>{record.win_amount}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container order-section mt-3'>
                        <div className='row'>
                            <div className='order-wraper col-9 d-flex'>
                                <div className='date-time-wraper'>
                                    <p>{formattedDate}</p>
                                    <p className='m-0'>{formattedTime}</p>
                                </div>
                                <div className='order-id-wraper'>
                                    <h2>Lost against {record.lose_username}</h2>
                                    <p>Order id: {record.creater_id}</p>
                                </div>
                            </div>
                            <div className='col-3 d-flex align-center justify-content-evenly'>
                                <span className='subtract-icon'>(-)</span>
                                <div className='d-flex align-center'>
                                    <span><img className='img-fluid' src={money_img} alt="Money"/></span>
                                    <h2 className='m-0 ms-2'>{record.battle_amount}</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        })}
        {
            data.length===0&&(
                <div className='container' style={{display:'flex', alignItems:'center' , height:'100vh'}}>
                  <div className='row m-auto'>
                    <div className='col-12 m-auto ms-4 empty-notification-wraper'>
                      <div className='row'>
                        <div className='col-lg-8 col-md-8 m-auto mt-2'>
                          <picture>
                              <img className='img-fluid text-center' src={empty_referrals}/>
                          </picture>
                        </div>
                        <h2>No, Record Found</h2>
                        <p>Seems like you haven’t done activity yet  </p>
                      </div>
                    </div>
                  </div>
                </div>
            )
           }
</>

    );
}

export default Lost_Win;
