import React, { useContext, useState } from "react";
import DashboardNav from "../Dashboard/DashboardNavbar/DashboardNav";
import logo from "../../image/logo.png";
import Ludo from "../../Component/Ludo";
import "../LiveBattle/LiveBattle.css";
import RunningBattleComponent from "./RunningBattleComponent";
import KycStatus from "../../KycStatus";
import { LUDO_API } from "../../config";
import Footer from "../../Component/Footer/Footer";
import { KycContext } from "../../context/AuthContext";
import axios from "axios";
import { useEffect } from "react";
import money_img from "../../image/Money.png";
import customer from "../../image/customer.png";

import { Dialog } from '@mui/material';
import playstore_img from '../../image/room_playstore.png';

import Echo from 'laravel-echo';
import Pusher from 'pusher-js';




function LiveBattle() {
  const [formData, setFormData] = useState({ battle_amount: "", battle_id: "" });
  const { userCrendential, battleLevel , kycstatus } = useContext(KycContext);
  const { user_id } = userCrendential;
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Challenge , setChallenge] = useState([])
  const[createRoom , setCreateRoom] = useState(false)
  const[createRoomForm , setCreateRoomForm] = useState({battle_id:'',roomcode:''})
  const [battle_id , setBattleId] = useState('')
  const [timer , settimer] = useState(0)
  const[message , setmessage] = useState('')
  const[waiting , setWaiting] = useState([])
  const [openRoom , setOpenRoom] = useState(false)
  const[roomvalidateMessage , setroomvalidateMessage] = useState('')
  const[getRoomData , setgetRoomData] = useState([])
  const[opensuccess , setopensuccess] = useState('')

  /**interval code start here */
  const [intervalId, setIntervalId] = useState(null);


  const startPolling = () => {
    if (intervalId) {
      clearInterval(intervalId); // Clear any existing interval
    }
    const id = setInterval(() => {
      startpollingFn({ preventDefault: () => {} });
    }, 1000);
    setIntervalId(id);
  };

  useEffect(() => {
    return () => {
      if (intervalId) {
        clearInterval(intervalId); // Clear interval on component unmount
      }
    };
  }, [intervalId]);

  /**intervel code end here */
  
console.log(getRoomData)
 
console.log(Challenge)
  const BattlePrice = Number(formData.battle_amount);
 console.log(formData)
 console.log(waiting)

 console.log('creater' , createRoom)
 console.log('opponent' , openRoom)


  // submit data in backend
  const HandleSubmit = async () => {
    await axios
      .post(`${LUDO_API}/api/battles/create`, {
        user_id: user_id,
        game_level: battleLevel,
        battle_amount: BattlePrice,
      })
      .then((response) => {
        console.log(response.data.data.battle_amount)
        setLoading(!loading);
        console.log(response)
        setFormData({
          ...formData,
          battle_amount:response.data.data.battle_amount,
          battle_id:response.data.data.id
        });
        setmessage('Battle created!')
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const PlayBattle = async (Battle_id) => {
    console.log("Battle id is " + Battle_id);
    setBattleId(Battle_id)
    await axios
      .post(`${LUDO_API}/api/battles/play-battle`, {
        battle_id: Battle_id,
        other_user_id: user_id,
      })
      .then((response) => {
        console.log(response);
        const array = [...waiting]
        array.push({battle_id:Battle_id,message:response.data.message})
        setWaiting(array)
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let fetchRecord = () => {
    axios.get(`${LUDO_API}/api/battles/play-battle-requests?battle_id=${formData.battle_id}`)
    .then(response => {
      console.log(response.data.message);
      setChallenge(response.data.message)
    })
    .catch(err => {
      console.log(err);
    });
  }

  //create room start here
  const handleCreateRoom = (id) => {
    
    setCreateRoomForm({
      ...createRoomForm,
      battle_id:id
    })
    axios.post(LUDO_API + "/api/battles/accept-reject-battle" , {
      open_battle_id:id,
      status:'Start'
    })
    .then(response=>{
      console.log(response)
      setCreateRoom(true)
    })
    .catch(err=>{
      console.log(err)
    })
  }

  const handleOppositeRoom = (e) => {
    e.preventDefault()
    axios.post(LUDO_API + "/api/battles/verify-enduser-roomcode" , {
      user_id:user_id,
      roomcode:getRoomData.roomcode
    })
    .then(response=>{
      console.log(response)
      setopensuccess(response.data.message)
    })
    .catch(err=>{
      console.log(err.response.data.message)
      setroomvalidateMessage(err.response.data.message)
      
    })
  }
  

  // save roomcode
  const handlePostRoom = (e) => {
    e.preventDefault()
    axios.post(LUDO_API + "/api/battles/check-roomcode-with-result" , createRoomForm)
    .then(response=>{
      console.log(response)
    })
    .catch(err=>{
      console.log(err.response.data.message)
      setroomvalidateMessage(err.response.data.message)
      
    })
  }

  // startpolling fn start here
  const startpollingFn = (e) => {
    e.preventDefault()
    axios.get(LUDO_API + "/api/battles/fetch-battle-status?roomcode="+createRoomForm.roomcode + '&' + 'battle_id='+ createRoomForm.battle_id )
    .then(response=>{
      console.log(response)
    })
    .catch(err=>{
      console.log(err)
      // setroomvalidateMessage(err.response.data.message)
      
    })
  }

  // handlereject game
  const handleRejectGame = (id) => {
    axios.post(LUDO_API + "/api/battles/accept-reject-battle" , {
      open_battle_id:id,
      status:'Reject'
    })
    .then(response=>{
      console.log(response)
    })
    .catch(err=>{
      console.log(err)
    })
  }
  
  useEffect(() => {
    // store data for intial time
    let fetchData = async () => {
      try {
        const response = await axios.get(`${LUDO_API}/api/battles/list?user_id=${user_id}`);
        const { message } = response.data;
        console.log(response)
        setData(message);
        settimer(timer+1)
      } catch (err) {
        console.log(err);
      }
    };
    fetchData();
    
    // let interval = setInterval(fetchData, 10000);

    // return () => clearInterval(interval)


  }, [user_id]);
  
  useEffect(() => {
    fetchRecord()
    // let intervalId = setInterval(fetchRecord, 10000);

    // return () => clearInterval(intervalId)
  },[loading,formData.battle_id]);

  useEffect(()=>{
    const fetchRoomCodeResult = () => {
      console.log('battle-id:'+battle_id)
      axios.get(LUDO_API + "/api/battles/fetch-roomcaode-to-enduser?battle_id=" + 7)
      .then(response=>{
        console.log(response)
      })
      .catch(err=>{
        console.log(err)
      })
    }
    fetchRoomCodeResult()
   
  },[timer])

  /** puster code start here  */

   // Enable pusher logging - don't include this in production
  //  Pusher.logToConsole = true;

  //  var pusher = new Pusher('1d638d962ef61b87f264', {
  //    cluster: 'ap2'
  //  });

  //  var channel = pusher.subscribe('ludo-game');
  //  channel.bind('my-event', function(data) {
  //    alert(JSON.stringify(data));
  //    console.log(JSON.parse(data))
  //  });

  /** puster code end here  */

 
// window.Pusher = Pusher;

// const echo = new Echo({
//     broadcaster: 'pusher',
//     key: '1d638d962ef61b87f264',
//     cluster: 'mt1',
//     wsHost: window.location.hostname,
//     wsPort: 6001,
//     wssPort: 6001,
//     forceTLS: false, // Change to true if using HTTPS
//     disableStats: true,
//     enabledTransports: ['ws', 'wss'], // Specify allowed transports
// });

// echo.channel('battles')
//     .listen('BattleUpdated', (data) => {
//         console.log('Battle updated', data);
//         // Update your state or UI accordingly
//     });

// websocket code start here

useEffect(() => {
  window.Pusher = Pusher;

  const echo = new Echo({
      broadcaster: 'pusher',
      key: '1d638d962ef61b87f264',
      cluster: 'ap2',
      forceTLS: true
  });

  echo.channel('battles')
      .listen('BattleUpdated', (e) => {
          console.log('Battle updated:', e.battles);
          setData(e.battles);
      });

  return () => {
      echo.disconnect();
  };
}, []);
useEffect(() => {
  window.Pusher = Pusher;

  const echo = new Echo({
      broadcaster: 'pusher',
      key: '1d638d962ef61b87f264',
      cluster: 'ap2',
      forceTLS: true
  });

  echo.channel('battlesopen')
      .listen('OpenBattles', (e) => {
          console.log('Battle updated:', e.battlesopen);
          setChallenge(e.battlesopen);
      });

  return () => {
      echo.disconnect();
  };
}, []);
useEffect(() => {
  window.Pusher = Pusher;

  const echo = new Echo({
      broadcaster: 'pusher',
      key: '1d638d962ef61b87f264',
      cluster: 'ap2',
      forceTLS: true
  });

  echo.channel('sendroomcodes')
      .listen('SendRoomcode', (e) => {
          // console.log('send room:', e.sendroomcodes);
          const lastData = e.sendroomcodes.at(-1)
          console.log(lastData)
          setOpenRoom(true)
          setgetRoomData(lastData);
      });

  return () => {
      echo.disconnect();
  };
}, []);
useEffect(() => {
  window.Pusher = Pusher;

  const echo = new Echo({
      broadcaster: 'pusher',
      key: '1d638d962ef61b87f264',
      cluster: 'ap2',
      forceTLS: true
  });

  echo.channel('battlesresult')
      .listen('BattleResult', (e) => {
          console.log('battle result:', e.battlesresult);
          ;
      });

  return () => {
      echo.disconnect();
  };
}, []);


// websocket code end here
 
  return (
    <>
      <div className="kyc-wraper">
        <div className="container-fluid ">
          <div className="row">
            <div className="col-lg-4 bg-white p-0">
              <DashboardNav />
              <div className="container">
                <div className="row m-auto">
                  <div className="col-12 m-auto  live-battle-wraper pb-2">
                    <div className="row pb-5">
                      <div className="col-12  m-auto mt-2 p-0 ">
                        <div className=" position-relative">
                          <KycStatus />
                      {
                        kycstatus.status==="approved" && (
                         <>
                         <p className="text-center mt-3">Create Battle!</p>
                         <div className="d-flex justify-content-center ">
                           <input
                             className="m-0 d-inline enter-amount-wraper"
                             type="text"
                             placeholder="Enter Amount"
                             onChange={(e) =>
                               setFormData({
                                 ...formData,
                                 battle_amount: e.target.value,
                               })
                             }
                           />
                           <button
                             onClick={HandleSubmit}
                             className="d-inline enter-amount-wraper-button"
                           >
                             <span>Set</span>
                           </button>
                         </div>
                         <p className="text-success mt-2 text-center">{message}</p>
                         </>
                        )

                      }
                         {
                      kycstatus.status==="approved" && (
                       <div className={`  border-wraper ${Challenge.length===0 ? 'd-none' : ''}`}></div>
                      )
                      }
                         
                        </div>
                        {
                          kycstatus.status==='approved' && (
                            <>
                            <div className={`align-center live-status-wraper my-3 mt-5 ${Challenge.length===0 ? 'd-none' : 'd-flex'}`}>
                          <span className="circle-list"></span>
                          <h2 className="my-0 ms-2">Open Battles</h2>
                        </div>
                        {
                          Challenge.map((record,i)=>(
                            <div className= {`col-lg-12 col-md-8 m-auto mt-3 live-info-wraper ${record.user_id===user_id ? '' :'d-none'}`} key={i}>
                            <div className={`d-flex justify-content-between entry-fee-wraper `}>
                              <div>
                                <p className="d-inline entry-fee text-uppercase">
                                  Playing For
                                </p>
                                <span>
                                  <img
                                    className="mx-2 img-fluid mb-2"
                                    src={money_img}
                                    alt="Money"
                                  />
                                  <h2 className="m-0">{record.battle_amount}</h2>
                                </span>
                              </div>
                              <div className="start-accept-game-wraper d-flex">
  
                                {
                                  record.status==="Pending" && (
                                    <>
                                    <button onClick={()=>handleCreateRoom(record.battle_id)} className="mx-1 text-white">Start</button>
                                     <button className="text-white bg-danger" onClick={()=>handleRejectGame(record.id)}>Reject</button>
                                    </>
                                  )
                                }
                                 {
                                record.status==="Reject" && (
                                 <p className="me-1 text-danger">Request rejected</p>
                                )
                                }
  
                              </div>
                            </div>
                            <div className="d-flex justify-content-between entry-fee-wraper">
                              <div>
                                <span className="p-3">
                                  <img
                                    className="mx-2 img-fluid mb-2"
                                    src={customer}
                                    alt="Customer"
                                  />
                                  <h2 className="m-0 text-uppercase">{record.username}</h2>
                                </span>
                              </div>
                              <span>V/S</span>
                              <div>
                                <span className="p-3">
                                  <img
                                    className="mx-2 img-fluid mb-2 "
                                    src={customer}
                                    alt="Customer"
                                  />
                                  <h2 className="m-0 text-uppercase">{record.other_username}</h2>
                                </span>
                              </div>
                            </div>
                          </div>
                          ))
                        }
                        <div className="border-wraper"></div>
                            </>
                          )
                        }

                        <div className={` align-center live-status-wraper my-3 mt-5 ${data.length===0 ? 'd-none' : 'd-flex'}`}>
                          <span className="circle-list"></span>
                          <h2 className="my-0 ms-2">Live Battles</h2>
                        </div>
                      </div>

                      {data?.map((record, i) => (
                        <div
                          className={`col-12 m-auto mt-3 live-info-wraper ${user_id===record.user_id ? 'd-none' : ''}`}
                          key={i}
                        >
                          <h3 className="m-0">
                            Challenge From {record.username}{" "}
                           
                          </h3>

                          {
                            waiting?.map((battle,index)=>(
                              record.id===battle.battle_id && (
                                <p className="text-danger mb-0 border-right border-left px-2 py-2 text-center fs-12">{battle.message}</p>
                              )
                            ))
                          }
                         
                         
                          <div className="d-flex justify-content-between entry-fee-wraper">
                            <div className="d-flex">
                              <div>
                                <p className="d-inline entry-fee text-uppercase">
                                  entry fee
                                </p>
                                <span>
                                  <img
                                    className="mx-2 img-fluid mb-2"
                                    src={money_img}
                                    alt=""
                                  />
                                  <h2 className="m-0">
                                    {record.battle_amount}
                                  </h2>
                                </span>
                              </div>
                              <div>
                                <p className="d-inline entry-fee text-uppercase">
                                  prize
                                </p>
                                <span>
                                  <img
                                    className="mx-2 img-fluid mb-2"
                                    src={money_img}
                                    alt=""
                                  />
                                  <h2 className="m-0">{record.win_amount}</h2>
                                </span>
                              </div>
                            </div>
                          
                            <button
                              className={`me-3 ${record.id===waiting.battle_id ? 'd-none' : null}`}
                              onClick={() => PlayBattle(record.id)}
                            >
                              <span className="text-uppercase">play</span>
                            </button>
                            <p className={`mb-0 px-3 text-danger ${record.id===waiting.battle_id ? '' : 'd-none '}`}>Waiting</p>
                          </div>
                         
                        </div>
                        
                      ))}

                      <div className="col-12 m-auto mt-2">
                        <div className="d-flex align-center live-status-wraper my-3">
                          <span className="running-circle-list mt-3"></span>
                          <h2 className="my-0 ms-2 mt-3">Running Battles</h2>
                        </div>
                      </div>
                      <RunningBattleComponent />
                     
                    </div>
                  </div>
                </div>
              </div>
              <Footer />
            </div>
            <div className="col-lg-8 col-md-12 text-center my-5 right-section">
              <div className="right-position-sticky">
                <picture>
                  <img src={logo} />
                </picture>
                <Ludo />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog open={createRoom}  maxWidth="sm" fullWidth>
    
      <div className='create-room-popup'>
      <button className='close-create-roompopup' onClick={()=>setCreateRoom(false)}>X</button>

        <p className='create-room-popup-heading-section'>
          <strong>News</strong>: Recharge your wallet without GST, now there’s no extra charge.
        </p>

        <form onSubmit={handlePostRoom}>
          <div className='create-room-popup-popup-section'>
            <h2>Room Code</h2>
            <p className="text-danger">{roomvalidateMessage }</p>
            <input autoFocus placeholder='Enter code' onChange={(e)=>setCreateRoomForm({
              ...createRoomForm,
              roomcode:e.target.value
            })} />
            <button onClick={startPolling} className='set-room-code-btn'>Set Room Code</button>
          </div>
        </form>

        <div className='play-store-img-wraper'>
          <button className='play-store-img'>
            <img src={playstore_img} alt='Play Store' />
          </button>
          <button className='play-store-img'>
            <img src={playstore_img} alt='Play Store' />
          </button>
        </div>
      </div>
    </Dialog>

    <Dialog open={openRoom && getRoomData.user_id!=user_id}  maxWidth="sm" fullWidth>
    
      <div className='create-room-popup'>
      <button className='close-create-roompopup' onClick={()=>setCreateRoom(false)}>X</button>

        <p className='create-room-popup-heading-section'>
          <strong>News</strong>: Recharge your wallet without GST, now there’s no extra charge.
        </p>
        <p>{opensuccess}</p>

        <form>
          <div className='create-room-popup-popup-section'>
            <h2>Room Code</h2>
            <input autoFocus placeholder='Enter code' value={getRoomData.roomcode} onChange={(e)=>setCreateRoomForm({
              ...createRoomForm,
              roomcode:e.target.value
            })} />
            <button onClick={handleOppositeRoom} className='set-room-code-btn'>Set Room Code</button>
          </div>
        </form>

        <div className='play-store-img-wraper'>
          <button className='play-store-img'>
            <img src={playstore_img} alt='Play Store' />
          </button>
          <button className='play-store-img'>
            <img src={playstore_img} alt='Play Store' />
          </button>
        </div>
      </div>
    </Dialog>
    </>
  );
}

export default LiveBattle;
