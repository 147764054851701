import React, { createContext, useEffect, useState } from 'react'
import axios from 'axios';
import { LUDO_API } from '../config';
export const KycContext = createContext()

function AuthContext({children}) {
    
    const [kycstatus , setKycStatus] = useState({user_username : null , user_id:'', user_email :'',status:''})
    const [login , setLogin] = useState(false)
    const [userCrendential , setuserCrendential] = useState({otp:"" , token :'' , user_id:'' , uid:'',isLogin:false})
    const [battleLevel , setLevel] = useState('')
    console.log(userCrendential)
    // console.log( kycstatus)

    
    const rec = {
      kycstatus ,
      setKycStatus,
      login,
      setLogin,
      userCrendential,
      setuserCrendential,
      battleLevel,
      setLevel
    }

    const fetchData = async () => {
      try {
          const response = await axios.get(`${LUDO_API}/api/get-user-profile?user_id=${userCrendential.user_id}`);
          console.log(response)
          const { user_username, user_email, status } = response.data;

          setKycStatus({
              ...kycstatus,
              user_username,
              user_email,
              status,
          });
      } catch (error) {
          console.error('Error fetching data:', error);
          // Handle the error, e.g., set a default state or show an error message
      }
  };

  useEffect(() => {
      fetchData();
  }, [userCrendential]);
  useEffect(()=>{
    let data = localStorage.getItem('auth')
    let parseData = JSON.parse(data)
    if (parseData) {
         setuserCrendential(parseData)
    }
  },[])
      return(
        <KycContext.Provider value={rec}>
            {children}
        </KycContext.Provider>
      )
  
}

export default AuthContext
