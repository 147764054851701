import React, { useState } from 'react';
import DashboardNav from '../Dashboard/DashboardNavbar/DashboardNav';
import logo from '../../image/logo.png';
import Ludo from '../../Component/Ludo';
import CustomPagination from '../../CustomPagination';
import '../Transaction/Transaction.css';
import Lost_Win from './Lost_Win';
import Footer from '../../Component/Footer/Footer';

function GameHistory() {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 1; // Number of items to display per page
  const totalItems = 10; // Total number of items

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  
  const transactions = Array.from({ length: totalItems }, (_, index) => (
    <Lost_Win key={index} />
  )).slice(startIndex, endIndex);

  const paginationWrapperStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '20px',
  };

  return (
    <>
      <div className="transaction-wraper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 bg-white p-0">
              <DashboardNav />

              {/* Pagination Section */}
             {
            //   <div style={paginationWrapperStyle} className='pagination-wraper'>
            //   <CustomPagination
            //     total={Math.ceil(totalItems / itemsPerPage)}
            //     currentPage={currentPage}
            //     onPageChange={handlePageChange}
            //   />
            // </div>
             }

              {/* Transactions Section */}
              <div className='container py-4'>
                <div className='row'> 
                  <div className='col-11 m-auto '>
                    {transactions}
                  </div>
                </div>
              </div>
              <Footer/>
            </div>
            <div className="col-lg-8 right-section   text-center my-5">
            <div className="right-position-sticky">
            <picture>
            <img src={logo} />
          </picture>
          <Ludo />
            </div>
            </div>
          </div>
        </div>
      </div>
    </>  
  );
}

export default GameHistory;
