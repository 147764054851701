import React, { Fragment, useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { LUDO_API } from '../../config';
import { KycContext } from '../../context/AuthContext';
import { format } from 'date-fns';



function Add_subtract_Transaction() {
    
    const { userCrendential } = useContext(KycContext);
    const [data, setData] = useState([]);
    console.log(data)
    console.log(userCrendential);

    useEffect(() => {
        axios.get(LUDO_API + "/api/user-transactions-by-id?user_id=" + userCrendential.user_id)
            .then(response => {
                setData(response.data.wallet);
            })
            .catch(err => {
                console.log(err);
                setData([])
            });
    }, [userCrendential.user_id]);

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = format(date, 'dd MMM');
        const formattedTime = format(date, 'h:mm a');
        return { formattedDate, formattedTime };
    };
  return (
   <>
   {
    data.map((record,index)=>{
        const { formattedDate, formattedTime } = formatDateTime(record.created_at);
        return (
            <Fragment>
    <div className='container order-section mt-3'>
    <div className='row'>
        <div className='order-wraper col-9  d-flex'>
            <div className='date-time-wraper'>
            <p>{formattedDate}</p>
            <p className='m-0'>{formattedTime}</p>
            </div>
            <div className='order-id-wraper' >
                <h2 >{record.type}</h2>
                <p>Order id : {record.order_id}</p>
            </div>
        </div>
        <div className='col-3 d-flex align-center justify-content-evenly'>
        <span className='add-icon'>(+)</span>
        <div className='d-flex align-center'>
{
    // <span><img className='img-fluid' src={money_img}/></span>

}        <h2 className='m-0 ms-2'>{record.balance}</h2>
        </div>
        </div>
    </div>
</div>
{
//     <div className='container order-section mt-3'>
//     <div className='row'>
//         <div className='order-wraper col-9  d-flex'>
//             <div className='date-time-wraper'>
//             <p>16 Jan</p>
//             <p className='m-0'>17:35 pm</p>
//             </div>
//             <div className='order-id-wraper' >
//                 <h2 >{record.type}</h2>
//                 <p>Order id : Cash78499555555</p>
//             </div>
//         </div>
//         <div className='col-3 d-flex align-center justify-content-evenly'>
//         <span className='subtract-icon'>(-)</span>
//         <div className='d-flex align-center'>
//         <span><img className='img-fluid' src={money_img}/></span>
//         <h2 className='m-0 ms-2'>800</h2>
//         </div>
//         </div>
//     </div>
// </div>
}
    </Fragment>
        )
    })
   }
   
  
   </>
    
  )
}

export default Add_subtract_Transaction
