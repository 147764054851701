import React, { Fragment, useEffect, useState } from 'react';
import DashboardNav from "../Dashboard/DashboardNavbar/DashboardNav";
import Ludo from "../../Component/Ludo";
import logo from "../../image/logo.png";
import "../MyProfile/walletProfile.css";
import profile_pic from '../../image/profile-pic.jpeg'
import wallet_pic from '../../image/wallet-pic.png'
import cash_pic from '../../image/cash.jpg'
import dollar_pic from '../../image/Money.png'
import mailbox_pic from '../../image/mailbox.png'
import battle_play_pic from '../../image/battle_play.jpg'
import { Link} from "react-router-dom";
import Footer from "../../Component/Footer/Footer";
import { useContext } from 'react';
import { KycContext } from '../../context/AuthContext';

function WalletProfile() {

  const rec = useContext(KycContext)
  const {kycstatus , setKycStatus} = rec
  const {user_username , user_email , status} = kycstatus

    let color = []
    if(kycstatus.status==='completed'){
      color.push('green')
    }else{
      color.push('red')
    }
   
    
    useEffect(() => {
      if (kycstatus.status === 'approved') {
        setKycStatus({
          ...kycstatus,
          status: 'completed'
        });
      }
    }, [kycstatus.status, setKycStatus]);
    

   
  

  return (
    <>
      <div className="kyc-wraper">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4 bg-white p-0">
              <DashboardNav />

              <div className="container mt-4" >
                <div className="row">
                    <div className="col-lg-10 col-md-8 col-sm-8 col-8 m-auto">
                        <div className="row">
                            <div className="col-lg-6 col-md-8 col-sm-12 col-12 d-flex m-auto">
                                <picture className="w-100 h-100">
                                    <img className="img-fluid" src={profile_pic}></img>
                                </picture>
                                <div className="col-6 contact-wraper">
                                    <p className="mb-1 text-uppercase">{user_username}</p>
                                    {
                                      //  <strong>LILY</strong>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-11  replay-wraper ">
                    <img className="replay-img" src={wallet_pic} alt="replay" />
                    <span className="history-wraper">Order History</span>
                  </div>
                </div>
              </div>

                <div className="container-fluid null-box">

                </div>

                <div className="container">
                    <strong className="ms-2">COMPLETE PROBLEM</strong>
                </div>

                <div className="container">
                <div className="row">
                  <Link  className="text-decoration-none" to={status === 'pending' ? '/kyc' : '/kyc-profile'}>
                  <div className="col-11  replay-wraper replay-wraper-common ">
                  <img className="replay-img" src={wallet_pic} alt="replay" />
                  <span className="history-wraper">Complete your KYC <span className={color.join(' ')}> &nbsp;({status})</span></span>
                </div>
                  </Link>
                </div>
              </div>

                <div className="container">
                <div className="row">
                  <div className="col-11  replay-wraper ">
                    <img className="replay-img" src={mailbox_pic} alt="replay" />
                    <span className="history-wraper">Upgrade your &nbsp; {user_email}</span>
                  </div>
                </div>
              </div>

              <div className="container-fluid null-box">

              </div>

              <div className="container">
                <div className="row">
                  <div className="col-11  replay-wraper ">
                    <img className="replay-img" src={cash_pic} alt="replay" />
                    <span className="history-wraper">Cash Win</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;<img className="ms-3" src={dollar_pic}/>&nbsp;<strong>2670.8</strong>
                  </div>
                </div>
              </div>

              <div className="container">
                <div className="row">
                  <div className="col-11  replay-wraper ">
                    <img className="replay-img" src={battle_play_pic} alt="replay" />
                    <span className="history-wraper">Battle Play &nbsp;&nbsp;<strong>00</strong></span>
                  </div>
                </div>
              </div>

              <div className="container col-11 replay-wraper-common">
              <button className='w-100 logout-btn  '><span>Log Out</span></button>
              </div>
              <Footer/>
            </div>
            <div className="col-lg-8 right-section   text-center my-5">
             <div className="right-position-sticky">
             <picture>
             <img src={logo} />
           </picture>
           <Ludo />
             </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default WalletProfile ;
