import React from 'react'
import money from "../../image/Money.png";
import first_ellipse from '../../image/first_ellipse.svg'
import second_ellipse from '../../image/second-ellipse.png'
import { useNavigate } from 'react-router-dom';


function WalletCard({value}) {
  const nav= useNavigate()
  return (
    <div className="col-lg-12 col-md-10 col-sm-12 wallet-card m-auto mb-4">
    <div className="d-flex justify-content-between">
      <div className="mt-3 ms-1 " style={{position:'absolute',zIndex:"1",left:"5px"}} >
        <img src={money} />
        <span>2,679.70</span>
        <p>Cash Deposit</p>
      </div>
      <div className="add-withdraw-cash-wraper mt-3 me-3">
        <button onClick={()=>nav('/add-money')}>
          <span className="text-uppercase">{value}</span>
        </button>
      </div>
    </div>

        <div className="gradient-wraper "></div>

    <div className="wallet-content-wraper ms-4">
      <p>Nunc tempus odio eu nunc auctor tristique. Curabitur iaculis ornare sem. Sed imperdiet</p>
    </div>

    <div className="first-ellipse-wraper">
       <img src={first_ellipse}/>
    </div>


    <div className="second-ellipse-wraper">
      <img src={second_ellipse}/>
    </div>

  </div>
  )
}

export default WalletCard
