import "./App.css";
import Home from "./Component/Home/Home";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import About from "./Component/About/About";
import Faq from "./Component/Faq/Faq";
import HowToPlay from "./Component/How_to_play/HowToPlay";
import Conditions from "./Component/TermsConditions/Conditions";
import PrivacyPolicy from "./Component/Privacy-policy/PrivacyPolicy";
import Login from "./Component/Auth/Login";
import Dashboard from "./UserDashboard/Dashboard/Dashboard";
import Kyc from "./UserDashboard/KycDashboard/Kyc";
import EmptyNotification from "./UserDashboard/Empty/EmptyNotification";
import EmptyReferralsHistory from "./UserDashboard/Empty/EmptyReferralsHistory";
import WalletProfile from "./UserDashboard/MyProfile/WalletProfile";
import Support from "./UserDashboard/Dashboard/Support";
import KycProfile from "./UserDashboard/KycDashboard/KycProfile";
import LiveBattle from "./UserDashboard/LiveBattle/LiveBattle";
import AddMoney from "./UserDashboard/Add-money/AddMoney";
import Transaction from "./UserDashboard/Transaction/Transaction";
import ReferAndEarn from "./UserDashboard/Refer-Earn/ReferAndEarn";
import GameHistory from "./UserDashboard/Transaction/GameHistory";
import MyProfile from "./UserDashboard/MyProfile/MyProfile";
import RefundPolicy from "./Component/Privacy-policy/RefundPolicy";
import Contact from "./Component/Privacy-policy/Contact";
import ResponsibleGaming from "./Component/Privacy-policy/ResponsibleGaming";
import Protected from "./Component/Protected";
import CreateRoomCodePupup from "./Component/room-code/CreateRoomCodePupup";
import WebSocket from './UserDashboard/LiveBattle/websocket'
import WithdrawlHistory from "./UserDashboard/MyProfile/WithdrawlHistory";

function App() {


  return (
    <>
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/about" element={<About />} />
          <Route exact path="/faq" element={<Faq />} />
          <Route exact path="/how-to-play" element={<HowToPlay />} />
          <Route exact path="/terms-conditions" element={<Conditions />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/refund-policy" element={<RefundPolicy />} />
          <Route exact path="/contact-us" element={<Contact />} />
          <Route exact path="/responsible-gaming" element={<ResponsibleGaming />}/>
          <Route exact path="/popup" element={<CreateRoomCodePupup />}/>
          <Route exact path="/test-socket" element={<WebSocket/>}/>


          <Route element={<Protected />}>

              <Route path="/dashboard" element={<Dashboard />} />
              <Route exact path="/kyc" element={<Kyc />}/>
              <Route exact path="/empty-notification" element={<EmptyNotification />}/>
              <Route exact path="/empty-referrals-history" element={<EmptyReferralsHistory />}/>
              <Route exact path="/wallet-profile" element={<WalletProfile /> }/>
              <Route exact path="/support" element={<Support />} />  
              <Route exact path="/kyc-profile" element={ <KycProfile /> }/>
              <Route exact path="/live-battle" element={ <LiveBattle /> }/>
              <Route exact path="/add-money" element={<AddMoney />}/>
              <Route exact path="/transaction" element={<Transaction />}/>
              <Route exact path="/refer-earn" element={<ReferAndEarn />} />
              <Route exact path="/game-history" element={<GameHistory /> }/>
              <Route exact path="/withdrawl-request" element={<WithdrawlHistory /> }/>
              
          <Route exact path="/my-profile" element={<MyProfile /> } />
          </Route>

          
          
      

          
        </Routes>
      </Router>
    </>
  );
}

export default App;
