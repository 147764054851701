import React from 'react'
import DashboardNav from '../Dashboard/DashboardNavbar/DashboardNav'
import logo from '../../image/logo.png'
import Ludo from '../../Component/Ludo'
import '../Transaction/Transaction.css'
import Add_subtract_Transaction from './Add_subtract_Transaction'
import Footer from '../../Component/Footer/Footer'

function Transaction() {
    const HandleSubmit = (e) => {
        e.preventDefault()
        console.log('clicked')
      }
  return (
    <>
    
    <div className="transaction-wraper">
      <div className="container-fluid">
      <div className="row">
      <div className="col-lg-4 bg-white p-0">
        <DashboardNav/>


        <div className='container py-4'>
          <div className='row'> 
            <div className='col-11 m-auto '>
                <Add_subtract_Transaction/>  
            </div>
          </div>
        </div>

      <Footer/>
      </div>
      <div className="col-lg-8 right-section   text-center my-5">
      <div className="right-position-sticky">
      <picture>
      <img src={logo} />
    </picture>
    <Ludo />
      </div>
      </div>
    </div>
      </div>
    </div>
    </>  
  )
}

export default Transaction
